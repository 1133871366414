import React, { ReactInstance, useEffect, useRef, useState } from 'react'
import * as deepar from 'deepar';
import styles from './index.module.css'
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from '../components/componentToPrint';
import { Modal } from '@mui/material';
import Webcam from 'react-webcam';
import ScreenSaver from '../components/screenSaver';
import { updateCount } from '../ApiService';


export default function Camera() {
    const webcamRef = useRef<any>(null)
    const [timer, setTimer] = useState<number>(null)
    const [takingPicture, setTakingPicture] = useState<boolean>(false)
    const [image, setImage] = useState<any>()
    const [modalConfirm, setModalConfirm] = useState<boolean>(false)
    const [openScreenSaver, setOpenScreenSaver] = useState<boolean>(false)

    useEffect(() => {
            if(timer === 3) {
                const audio = new Audio('/assets/audios/prepare.mp3')
                audio.play()
            }
    
            if (timer === 0) {
                savePicture();
            }
    
            const interval = setInterval(() => {
                setTimer((prevSeconds) => prevSeconds? prevSeconds - 1 : null);
            }, 1000);
            
            // Clean up the timer
            return () => {
                clearInterval(interval);
            }
    }, [timer]);


    useEffect(() => {
        if (modalConfirm) {
            setOpenScreenSaver(false)
        }
        if (takingPicture) {
            setOpenScreenSaver(false)
        }
        if(!modalConfirm && !openScreenSaver && !takingPicture) {
            if (timer === 0 || !timer) {
                setTimeout(() => setOpenScreenSaver(true),60000)   
            }
        }
    },[modalConfirm, openScreenSaver]);

    const takeScreenshot = async () => {
        setTimer(3)
        setTakingPicture(true)
        countImage()
    }

    const savePicture = async () => {
        const image = webcamRef?.current?.getScreenshot()
        setImage(image)
        setModalConfirm(true)
        setTakingPicture(false)
    }

    const countImage = async () => {
        await updateCount()
    }

  return (
    <div className={styles.background}>
        <Modal open={modalConfirm} onClose={() => setModalConfirm(false)}>
            <ComponentToPrint image={image} closeCallback={() => setModalConfirm(false)}/>
        </Modal>
        <Modal open={openScreenSaver} onClose={() => setOpenScreenSaver(false)}>
            <ScreenSaver closeCallback={() => setOpenScreenSaver(false)}/>
        </Modal>
        <div className={styles.nextLevel}>
            
        </div>
        <div className={styles.cameraDiv}>
            <Webcam className={styles.camera} ref={webcamRef}/>
            <p className={styles.timer}>{timer !== 0 && timer}</p>
        </div>
        <div className={styles.takePictureButton} onClick={takeScreenshot}>
            <img src='/assets/images/camera.png' className={styles.cameraIcon} />
        </div>
        <div>
            <img className={styles.nextLevelLogo} src='/assets/images/nextlevel.png' />
        </div>
    </div>
  )
}
