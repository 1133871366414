import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Camera from "./camera";

const Router = () => {
    return(
        <BrowserRouter>
        <Routes>
            <Route element={<Camera />}  path="/home" />
        </Routes>
        </BrowserRouter>
    )
 }
 
 export default Router;