import React, { useEffect, useState } from 'react'
import styles from './componentToPrint.module.css'
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { savePicture, updatePrint } from '../ApiService';

interface Props {
    image: any;
    closeCallback: () => void;
}

export default function ComponentToPrint(props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handlePrint = () => {
        setIsLoading(true)
        countPrint()
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.style.width = '386px'
        iframe.style.height = '408px'

        const backgroundImage = new Image()
        backgroundImage.src = "/assets/images/print_background.png"

        const mainImg = new Image();
        mainImg.src = props.image;
    
        const checkImagesLoaded = () => {
            if (backgroundImage.complete && mainImg.complete) {
                iframe?.contentDocument?.write(`
                    <img
                        src="/assets/images/print_background.png"
                        style="width: 386px; height: 588px;"
                    />
                    <img 
                        src="${props.image}" 
                        style="width: 338px; height: 408px; position:fixed; top: 30px; left: 32px; object-fit: cover;"
                        onload="window.print(); window.onafterprint = function() { window.close(); }" 
                    />
                `);
                iframe?.contentDocument?.close();
            } else {
                setTimeout(checkImagesLoaded, 100);
            }
        };
    
        checkImagesLoaded();
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            props.closeCallback()
        },15000)

        return () => {
            clearTimeout(timeout);
        }
    },[isLoading])


    const countPrint = async () => {
        updatePrint()
        savePicture(props.image)
    }

  return (
    <div className={styles.background}>
        <div className={styles.row}>
            <div className={styles.takePictureButton} onClick={props.closeCallback}>
                <img className={styles.backIcon} src='/assets/images/back.png' />
                <p>Tirar outra foto</p>
            </div>
        </div>
        <img className={styles.imagePreview} src={props.image} />
        {isLoading?
            <div className={styles.takePictureButton} onClick={handlePrint}>
                <img className={styles.loadingIcon} src='/assets/images/loading.png' />
            </div>
        :
            <div className={styles.takePictureButton} onClick={handlePrint}>
                <p>Imprimir</p>
            </div>
        }
    </div>
  )
}
