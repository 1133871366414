import { initializeApp } from "firebase/app";
import { doc, getFirestore, updateDoc, increment } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyBCOdfK4o-YBlQJpWoxLoI7GM4FP7R-JYM",
    authDomain: "camera-b916d.firebaseapp.com",
    projectId: "camera-b916d",
    storageBucket: "camera-b916d.appspot.com",
    messagingSenderId: "188252431541",
    appId: "1:188252431541:web:5bf721d90b4f7b8b8d4afc",
    measurementId: "G-ZCLLK4Z9QB"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();


export async function updateCount() {
    const response = await updateDoc(doc(db,'johnson','contagem'),{
        fotos: increment(1)
    })
}

export async function updatePrint() {
    const response = await updateDoc(doc(db,'johnson','contagem'),{
        impressoes: increment(1)
    })
}

export async function savePicture(image: any) {
    const now = new Date()
    let blob = await fetch(image).then(r => r.blob());
    const storageRef = ref(storage, `${now.toISOString()}.webp`); 
    const response = await uploadBytes(storageRef, blob)
}


