import React, { useEffect, useState } from 'react'
import styles from './screenSaver.module.css'

interface Props {
    closeCallback: () => void
}

export default function ScreenSaver(props:Props) {
    const [image, setImage] = useState<string>()
    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        const imageArray = ['background1', 'background2']
        const interval = setInterval(() => {
            if(count <= 1) {
                setImage(imageArray[count])
                setCount(c => c+1)
            }
            else {
                const index = count%2
                setImage(imageArray[index])
                setCount(c => c+1)
            }
        },5000)

        return () => {
            clearInterval(interval);
        }
    })
  return (
    <div onMouseMove={props.closeCallback}>
        <img className={styles.background} src={`/assets/images/${image ?? 'background1'}.png`}/>
    </div>
  )
}
